import React from "react"
import Img from "gatsby-image"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <>
    <SEO title="Home" />
    <div id="home-page">
      <h1 className="page-title">
        About Us
      </h1>
      <div className="special-image-container">
        <Img className="as-1" fluid={data.fam.childImageSharp.fluid} />
        <Img className="as-2" fluid={data.store.childImageSharp.fluid} />
      </div>
      <p>
        Two sisters and proud owners of Canvas Craze, Lori Woodall and Genia Kirby, 
        know a picture is worth a thousand words and that memories are priceless.  
        We are dedicated to helping our customers capture and preserve their most memorable moments.   
        We pride ourselves on our customer service, turnaround time and pricing, which are all second to none. 
      </p>
      <div className="special-image-container">
        <Img fluid={data.chase.childImageSharp.fluid} />
      </div>
      <p>
        At Canvas Craze, we print and stretch all our canvases and our dad, Eddie Woodall, 
        hand makes our frames in house at our shop located at 500 Belwood Rd in Calhoun, Georgia.  
        We can also use your pictures to create canvas scrolls. We’ve recently added to our offerings canvas signs 
        with your favorite sayings, wood framed signs and canvases,  specialty collages, vinyl banners/signs, 
        celebration yard pop up signs, framing  and more.
      </p>
      <p>
        Lori is married to Brent Whitley. They love to travel and spend time on their boat in Chattanooga.
      </p>
      <p>
        Genia is married to Chuck Kirby and they have 3 children, Alex, Callie and Chase. 
        Genia loves  taking and editing pictures, sports and spending time with her family. 
      </p>
      <p>
        Lori, Genia and Eddie love spending time at Canvas Craze and making beautiful creations for our 
        customs to capture all their priceless memories. 
      </p>
      <div className="special-image-container">
        <Img fluid={data.callie.childImageSharp.fluid} />
      </div>
    </div>
  </>
)

export default IndexPage

export const query = graphql`
{
  fam: file(name: {eq: "about_us_fam_pic"}) {
    id
    childImageSharp {
      fluid(maxWidth: 300, quality: 50) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  store: file(name: {eq: "fish-lense-store"}) {
    id
    childImageSharp {
      fluid(maxWidth: 300, quality: 50) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  chase: file(name: {eq: "chase"}) {
    id
    childImageSharp {
      fluid(maxWidth: 300, quality: 50) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  callie: file(name: {eq: "callie"}) {
    id
    childImageSharp {
      fluid(maxWidth: 300, quality: 50) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}`;
